<template>

    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="70vw"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}菜单</div>

        <div class="content" v-loading="loading">
            <el-form class="column2" label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="父级菜单" prop="parentId" ref="parentId">

                    <el-cascader
                        v-model="form.parentId"
                        :options="treeselectOptions"
                        :props="{ expandTrigger: 'hover',checkStrictly:true,emitPath:false,label:'name',value:'id' }"
                        @change="changeParentId"></el-cascader>


                </el-form-item>

                <el-form-item label="类型" prop="typeId">
                    <el-radio-group v-model="form.typeId" size="medium">
                        <el-radio :label="1">按钮</el-radio>
                        <el-radio :label="0">菜单</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item :label="form.typeId==1?'按钮名称':'菜单名称'" prop="name">
                    <el-input v-model="form.name" :placeholder="`请输入${form.typeId==1?'按钮':'菜单'}名称`" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="权限标识" prop="code">
                    <el-input v-model="form.code" placeholder="请输入权限标识" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="菜单链接" prop="href" v-if="form.typeId===0">
                    <el-input v-model="form.href" placeholder="请输入菜单链接" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="linkTo" prop="href" v-if="form.typeId===0">
                    <el-input v-model="form.linkTo" placeholder="请输入linkTo" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="组件路径" prop="component" v-if="form.typeId===0">
                    <el-input v-model="form.component" placeholder="请输入组件路径" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="图标" prop="icon"  v-if="form.typeId===0">
                    <el-autocomplete
                        popperClass="autocompleteIcon"
                        @keyup.enter.native="submit"
                        v-model="form.icon"
                        :fetch-suggestions="regularQuerySearch"
                        placeholder="请输入图标"
                        @select="regularHandleSelect"
                    >
                        <div slot-scope="{item}" :class="`iconfont icon${item['font_class']}`"></div>
                    </el-autocomplete>
                </el-form-item>


                <el-form-item label="排序" prop="sort" v-if="form.typeId===0">
                    <el-input v-model="form.sort" placeholder="请输入排序" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="关联表单" prop="tableName">
                    <ChoiceData :configure="{request:$api['admin/formConfig'].findPage,type:'radio'}" @choiceDataChange="choiceDataChange">
                        <el-input slot="button" :readonly="true" v-model="form.tableLabel"  placeholder="请选择表名">
                            <i @click.stop.prevent="form.tableName=null" slot="suffix" class="el-input__icon el-icon-close"></i>
                        </el-input>
                        <template slot="tableColumn">
                            <el-table-column align="center" prop="tableName" label="表名"></el-table-column>
                            <el-table-column prop="tableLabel" align="center" label="表别名"></el-table-column>
                        </template>
                    </ChoiceData>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import mixinsForm from '@/mixins/form'

import iconJson from '@/styles/icon/iconfont.json'
import {mapState} from "vuex";
import {initTreeData} from "@/util/common";
export default {
    components: {
        ChoiceData: () => import("@/components/ChoiceData.vue"),
    },
    mixins: [mixinsForm],
    props: {
        listData: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{
                save:this.$api['admin/menu'].save,
            },
            treeselectOptions:[],
            rules: {
                parentId: [
                    {required: true, message: '请选择父级菜单', trigger: ['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.form.id==value){
                                callback(new Error('父级菜单不能为自身'));
                            };
                            callback();
                        }
                    }
                ],
                typeId: [{required: true, message: '请选择权限类型', trigger: ['blur','change']}],
                code: [
                    {required: true, message: '请填写权限标识', trigger: ['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.listData.some(r=>{return r.id!=this.form.id&&r.code==value})){
                                callback(new Error('权限标识不能重复'));
                            };
                            callback();
                        }
                    }
                ],
            },
        };
    },
    computed: {
        ...mapState({
            menus: state => state.user.menus,
        })
    },
    watch: {
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                this.getTreeselectOptions();
            }
        },
        listData:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                this.getTreeselectOptions();
            }
        },
    },
    methods: {
        init(options){
            if(options.tableName){
                this.$api['admin/formConfig'].getField(options.tableName).then( res => {
                    this.form = options.combination({tableLabel:res.data.info.tableLabel});
                    this.loading = false;
                }).catch(err=>{
                    console.log(err);
                })
            }else {
                this.form = options.deepClone();
                this.loading = false;
            }
        },
        choiceDataChange(res){
            this.$set(this.form, 'tableName', res.id);
            this.$set(this.form, 'tableLabel', res.tableLabel);
        },
        getTreeselectOptions(){
            var arr = [];
            if(this.options.id){
                this.listData.some(r=>{
                    if(r.id!=this.options.id&&r.typeId!=1){
                        arr.push(r.deepClone())
                    }
                })
            }else {
                this.listData.some(r=>{
                    if(r.typeId!=1){
                        arr.push(r.deepClone())
                    }
                })
            };
            this.treeselectOptions = [
                {
                    id:'0',
                    name:'顶级菜单',
                    children:initTreeData(arr)
                }
            ];
        },
        regularQuerySearch(queryString, cb) {
            var restaurants = iconJson.glyphs;
            if(queryString){
                var arr = []
                restaurants.some(r=>{
                    if(r.name.includes(queryString)){
                        arr.push(r)
                    }
                })
                cb(arr);
            }else {
                cb(restaurants);
            }
        },
        regularHandleSelect(item) {
            this.form.icon = `${iconJson['css_prefix_text']}${item['font_class']}`;
        },
        changeParentId(e){
            this.$refs.form.validateField('parentId');
        },
    },
};
</script>
